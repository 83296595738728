<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
        <v-toolbar-title>
            {{$t('table.assign_roles')}}
        </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form>
          <v-alert type="warning" v-if="formAlert">{{formError}}</v-alert>
          <v-alert type="info" v-if="formSuccessTrue">Role Assigned</v-alert>
          <v-radio-group v-model="formData.role" :mandatory="false">
              <p class="pb-2">{{$t('admin_type')}}</p>
            <v-radio v-for="role in roleData" :key="role.id" :label="role.name" :value="role.id"></v-radio>
          </v-radio-group>
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["userInfoDialog"],
  name: "roleModal",
  watch: {
    updated: {
      handler: function(val) {
        this.parentResponseData.val = val;
        this.$emit("formResponse",  this.parentResponseData);
      },
      deep: true
    }
  },
  data() {
    return {
      parentResponseData: {},
      formError: "",
      formSuccess: "",
      formAlert: false,
      formSuccessTrue: false,
      updated: false,
      roleData: {},
      updatedData: {},
      formData: {
        userID: "",
        isActive: false,
        role: ""
      },
      servicesData:{
        data:{},
        message:"",
        color:""
      }
    };
  },
  mounted() {
    this.formOption();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData)
      this.$emit("formResponse", this.servicesData);
    },
    async formOption() {
      const response = await axios.get("User/GetAssignRoles", {
        params: { UserID: this.userInfoDialog }
      });
      this.updatedData = response.data;
      axios
        .get("User/GetRolesList")
        .then(response => {
            this.roleData = response.data;
            console.log(this.roleData)
        })
        .catch(e => {
          console.log(e);
        });
      this.formData.role = response.data.roleID;
      this.formData.isActive = response.data.isActive;
      console.log(this.updatedData);
    },
    async submit() {
      this.updatedData.isActive = this.formData.isActive;
      this.updatedData.roleID = this.formData.role;

        await axios.post("user/AssignRoles", this.updatedData).then(response => {
            if(response.data.success == false){
                this.servicesData.message = response.data.errors[0];
                this.servicesData.color="error"
                this.dialogueClose()
            }
            else
            {
                this.editedData = {};
                this.servicesData.data=response.data
                this.servicesData.message="Role Updated Succcessfully"
                this.servicesData.color="success"
                this.dialogueClose()
             }

      }).catch(response=>{
        this.servicesData.data=response.data
        this.servicesData.message="Error Adding Service, Please Contact Admin"
        this.servicesData.color="error"
        this.dialogueClose()

      });
    }
  }
};
</script>

<style lang="scss" scoped>
.hr-divider {
  width: 100%;
  max-width: 100%;
  border-width: 1px;
}
</style>