<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("table.add_user") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-select
            v-if="isProvince" 
            v-model="editedUser.provinceId"
            :items="province"
            :label="$t('form.select_province')"
            item-value="id"
            item-text="value"
          ></v-select>
          <v-select
            v-if="isDivision"
            v-model="editedUser.divisionId"
            :items="division"
            :label="$t('form.select_division')"
            item-value="id"
            item-text="value"
          ></v-select>
          <v-select
            v-if="isBranch"
            v-model="editedUser.branchId"
            :items="branch"
            :label="$t('form.select_brand')"
            item-value="id"
            item-text="value"
          ></v-select>
          <v-text-field
            v-model="editedUser.firstName"
            :error-messages="firstNameErrors"
            :label="$t('first_name')"
            required outlined dense
            @input="$v.editedUser.firstName.$touch()"
            @blur="$v.editedUser.firstName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="editedUser.lastName"
            :error-messages="lastNameErrors"
            :label="$t('last_name')"
            required outlined dense
            @input="$v.editedUser.lastName.$touch()"
            @blur="$v.editedUser.lastName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="editedUser.designation"
            :label="$t('designation')" outlined dense
          ></v-text-field>
          <v-text-field
            v-model="editedUser.userName"
            :error-messages="userNameErrors"
            :label="$t('user_name')"
            required outlined dense
            @input="$v.editedUser.userName.$touch()"
            @blur="$v.editedUser.userName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="editedUser.email"
            :error-messages="emailErrors"
            :label="$t('email')"
            required outlined dense
            @input="$v.editedUser.email.$touch()"
            @blur="$v.editedUser.email.$touch()"
          ></v-text-field>
          <v-text-field
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="editedUser.password"
            :error-messages="passwordErrors"
            :label="$t('password')"
            required outlined dense
            @input="$v.editedUser.password.$touch()"
            @blur="$v.editedUser.password.$touch()"
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-text-field
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="editedUser.confirmPassword"
            :error-messages="confirmPasswordErrors"
            :label="$t('confirm_password')"
            required outlined dense
            @input="$v.editedUser.confirmPassword.$touch()"
            @blur="$v.editedUser.confirmPassword.$touch()"
            @click:append="show2 = !show2"
          ></v-text-field>
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{
        $t("form.cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{
        $t("form.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { email, maxLength, required, sameAs } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "createAdminForm",
  props: ["success"],
  watch: {
    "editedUser.provinceId": {
      handler: async function(val) {
        if (val) {
          this.error = true;
          this.division = [];
          this.branch = [];
          const divisionBranch = await axios.get("Division/DDLDivisionList", {
            params: { ProvinceID: val },
          });
          this.division = divisionBranch.data;
          //  this.editedUser.divisionId = val;
        }
      },
      deep: true,
    },
    "editedUser.divisionId": {
      handler: async function(val, oldval) {
        if (val && oldval != "") {
          console.log(val);
          this.branch = [];
          const branch = await axios.get("Branch/DDLBranchList", {
            params: { DivisionID: val },
          });

          this.branch = branch.data;
          //    this.editedUser.branchId = val;
        }
      },
      deep: true,
    },
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.firstName.$dirty) return errors;
        !this.$v.editedUser.firstName.required &&
          errors.push(`${this.$t("first_name")} ${this.$t("is_required")}`);
      }
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.lastName.$dirty) return errors;
        !this.$v.editedUser.lastName.required &&
          errors.push(`${this.$t("last_name")} ${this.$t("is_required")}`);
      }
      return errors;
    },

    userNameErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.userName.$dirty) return errors;
        !this.$v.editedUser.userName.required &&
          errors.push(`${this.$t("user_name")} ${this.$t("is_required")}`);
      }
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.password.$dirty) return errors;
        !this.$v.editedUser.password.required &&
          errors.push(`${this.$t("password")} ${this.$t("is_required")}`);
      }
      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.confirmPassword.$dirty) return errors;
        !this.$v.editedUser.confirmPassword.required &&
          errors.push(
            `${this.$t("to_confirm_password")} ${this.$t("is_required")}`
          );
        !this.$v.editedUser.confirmPassword.sameAsPassword &&
          errors.push(`${this.$t("password")} ${this.$t("must_be_identical")}`);
      }
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (this.error) {
        if (!this.$v.editedUser.email.$dirty) return errors;
        !this.$v.editedUser.email.email &&
          errors.push(`${this.$t("email")} ${this.$t("must_be_valid")}`);
        !this.$v.editedUser.email.required &&
          errors.push(`${this.$t("email")} ${this.$t("is_required")}`);
      }
      return errors;
    },
    isProvince() {
      let check = this.tokenData.role == "Ministry";
      return check;
    },
    isDivision() {
      let check =
        this.tokenData.role == "Ministry" || this.tokenData.role == "Province";
      return check;
    },
    isBranch() {
      let check =
        this.tokenData.role == "Ministry" ||
        this.tokenData.role == "Province" ||
        this.tokenData.role == "Division";
      return check;
    },
  },
  data() {
    return {
      error: false,
      created: false,
      show1: false,
      show2: false,
      provShow: false,
      divShow: false,
      branchShow: true,
      province: [],
      division: [],
      branch: [],
      editedUser: {
        divisionId: 0,
        provinceId: 0,
        branchId: 0,
        designation: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        confirmPassword: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedUser: {
      firstName: { required, maxLength: maxLength(10) },
      lastName: { required },
      userName: { required },
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
    },
  },
  created() {
    this.formData();
    this.error = true;
  },
  destroyed() {},
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      let permissionData = JSON.parse(localStorage.getItem("tokenData"));
      if (permissionData.role == "Ministry") {
        this.provShow = true;
        this.divShow = true;
        const provinceRequest = await axios.get("Province/DDLProvinceList");
        this.province = provinceRequest.data;
      } else if (permissionData.role == "Province") {
        this.divShow = true;
        this.editedUser.provinceId = permissionData.ProvinceID;
      } else if (permissionData.role == "Division") {
        this.editedUser.provinceId = permissionData.ProvinceID;
        this.editedUser.divisionId = permissionData.DivisionID;
      } else if (permissionData.role == "Branch") {
        this.editedUser.provinceId = permissionData.ProvinceID;
        this.editedUser.divisionId = permissionData.DivisionID;
        this.editedUser.branchId = permissionData.BranchID;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var form = new FormData();
        form.append("UserDetails.FirstName", this.editedUser.firstName);
        form.append("UserDetails.MinistryID", 1);
        form.append("UserDetails.LastName", this.editedUser.lastName);
        form.append("UserDetails.Email", this.editedUser.email);
        form.append("UserDetails.UserName", this.editedUser.userName);
        form.append("UserDetails.Password", this.editedUser.password);
        form.append("UserDetails.DivisionID", this.editedUser.divisionId);
        form.append("UserDetails.ProvinceID", this.editedUser.provinceId);
        form.append("UserDetails.BranchID", this.editedUser.branchId);
        form.append("UserDetails.Designation", this.editedUser.designation);
        form.append("isAnonymous", "false");
        axios
          .post("User/CreateUser", form)
          .then((response) => {
            this.editedData = {};
            this.servicesData.data = response.data;
            this.servicesData.message = response.data.success
              ? "User added Succcessfully"
              : response.data.errors[0];
            this.servicesData.color = "success";
            this.dialogueClose();
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
$blue: #236aac;
$blue1: #061c6d;
$red: #b72626;
$grey: #eeeeee51;
$grey1: #eeeeee99;
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $grey1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e434361;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e434361;
}
</style>
