<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("table.edit_user") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form>
          <v-select
            v-if="provShow"
            v-model="editedUser.provinceID"
            :items="province"
            :label="$t('form.select_province')"
            item-value="id"
            item-text="value"
          ></v-select>
          <v-select
            v-if="divShow"
            v-model="editedUser.divisionID"
            :items="division"
            :label="$t('form.select_division')"
            item-value="id"
            item-text="value"
          ></v-select>
          <v-select
            v-model="editedUser.branchID"
            :items="branch"
            :label="$t('form.select_brand')"
            item-value="id"
            item-text="value" outlined dense
          ></v-select>
          <v-text-field
            v-model="editedUser.firstName"
            :error-messages="firstNameErrors"
            :label="$t('first_name')"
            required outlined dense
            @input="$v.editedUser.firstName.$touch()"
            @blur="$v.editedUser.firstName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="editedUser.lastName"
            :error-messages="lastNameErrors"
            :label="$t('last_name')"
            required outlined dense
            @input="$v.editedUser.lastName.$touch()"
            @blur="$v.editedUser.lastName.$touch()"
          ></v-text-field>
          <v-text-field
                  v-model="editedUser.designation"
                  :label="$t('designation')" outlined dense
          ></v-text-field>
          <v-text-field
            v-model="editedUser.userName"
            :error-messages="userNameErrors"
            :label="$t('user_name')"
            required outlined dense
            @input="$v.editedUser.userName.$touch()"
            @blur="$v.editedUser.userName.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="editedUser.email"
            :error-messages="emailErrors"
            :label="$t('email')"
            required outlined dense
            @input="$v.editedUser.email.$touch()"
            @blur="$v.editedUser.email.$touch()"
          ></v-text-field>
          <!-- <v-text-field v-model="editedUser.password" label="Password"></v-text-field>
          <v-text-field v-model="editedUser.confirmPassword" label="Confirm Pasword"></v-text-field>-->
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t("form.cancel")}}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{$t("form.save")}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { email, maxLength, required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "editAdminForm",
  props: ["userInfoDialog", "success"],
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.editedUser.firstName.$dirty) return errors;
      !this.$v.editedUser.firstName.required &&
        errors.push("First Name is required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.editedUser.lastName.$dirty) return errors;
      !this.$v.editedUser.lastName.required &&
        errors.push("Last Name is required.");
      return errors;
    },
    userNameErrors() {
      const errors = [];
      if (!this.$v.editedUser.userName.$dirty) return errors;
      !this.$v.editedUser.userName.required &&
        errors.push("User Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedUser.email.$dirty) return errors;
      !this.$v.editedUser.email.email && errors.push("Must be valid e-mail");
      !this.$v.editedUser.email.required && errors.push("E-mail is required");
      return errors;
    }
  },
  watch: {
    "editedUser.provinceID": {
      handler: async function(val) {
        if (val) {
          this.division = [];
          this.branch = [];
          let url = "Division/DDLDivisionList";
          const divisionBranch = await axios.get(url, {
            params: { ProvinceID: val }
          });
          if (divisionBranch.data[0]) {
            this.division = divisionBranch.data;
          } else {
            this.editedUser.divisionID = 0;
            this.editedUser.branchID = 0;
          }

          if (this.watchProvince) {
            this.editedUser.divisionID = val;
          }
          this.watchProvince = true;
        }
      },
      deep: true
    },
    "editedUser.divisionID": {
      handler: async function(val) {
        if (val) {
          this.branch = [];
          const branch = await axios.get("Branch/DDLBranchList", {
            params: { DivisionID: val }
          });
          if (branch.data[0]) {
            this.branch = branch.data;
          } else {
            this.editedUser.branchID = 0;
          }

          if (this.watchDivision) {
            this.editedUser.branchID = val;
            this.watchSector = true;
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      watchDivision: false,
      watchProvince: false,
      updated: false,
      show1: false,
      show2: false,
      provShow: false,
      divShow: false,
      province: [],
      division: [],
      branch: [],
      editedUser: {},
      servicesData:{
        data:{},
        message:"",
        color:""
      }
    };
  },
  validations: {
    editedUser: {
      firstName: { required, maxLength: maxLength(10) },
      lastName: { required },
      userName: { required },
      email: { required, email }
    }
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData)
      this.$emit("formResponse", this.servicesData);
    },
    submit() {
      console.log("test")
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var formData = new FormData();
 formData.append(
          "UserDetails.FirstName",
          this.editedUser.firstName
        );
        formData.append("UserDetails.MinistryID", 1);
        formData.append(
          "UserDetails.LastName",
          this.editedUser.lastName
        );
        formData.append(
          "UserDetails.Address",
          this.editedUser.address
        );
        formData.append("UserDetails.Email", this.editedUser.email);
        formData.append(
          "UserDetails.UserName",
          this.editedUser.userName
        );
        formData.append(
          "UserDetails.Password",
          this.editedUser.password
        );
        formData.append(
          "UserDetails.DivisionID",
          this.editedUser.divisionId
        );
        formData.append(
          "UserDetails.ProvinceID",
          this.editedUser.provinceId
          );
            formData.append(
           "UserDetails.Designation",
           this.editedUser.designation
         );
        formData.append("isAnonymous", "false");
        axios.post("User/UpdateUser", this.editedUser).then(response=> {
          this.editedData = {};
          this.servicesData.data=response.data
          this.servicesData.message="User Updated Succcessfully"
          this.servicesData.color="success"
          this.dialogueClose()
        }).catch(response=>{
          this.servicesData.data=response.data
          this.servicesData.message="Error Adding Service, Please Contact Admin"
          this.servicesData.color="error"
          this.dialogueClose()

        });
      }
    },
    async formData() {
      let permissionData = JSON.parse(localStorage.getItem("tokenData"));
      if (permissionData.role == "Ministry") {
        this.provShow = true;
        this.divShow = true;
        const provinceRequest = await axios.post("Province/ProvinceList", {});
        this.province = provinceRequest.data.data;
      } else if (permissionData.role == "Province") {
        this.divShow = true;
      }
      const provinceRequest = await axios.get("Province/DDLProvinceList");
      this.province = provinceRequest.data;
      axios
        .get("User/GetUserByID", { params: { UserID: this.userInfoDialog } })
        .then(response => {
          this.editedUser= response.data
        });
    }
  }
};
</script>

<style scoped>
</style>