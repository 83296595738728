<template>
    <v-card>
        <v-card-title>
            <span class="headline">Assign Permission</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <v-expansion-panels multiple>
                        <v-row>
                            <v-col cols="12" md="4" v-for="(formData,index) in permission" :key="formData.id">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{formData.menuName}}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_R']"
                                                @change="serverDataUpdate(index,'readAccess',$event)"
                                                label="Read"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_W']"
                                                @change="serverDataUpdate(index,'readWriteAccess',$event)"
                                                label="Read Write"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_E']"
                                                @change="serverDataUpdate(index,'exportAccess',$event)"
                                                label="Export"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_P']"
                                                @change="serverDataUpdate(index,'printAccess',$event)"
                                                label="Print"
                                        ></v-checkbox>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-col>
                        </v-row>
                    </v-expansion-panels>
                    <v-btn class="mr-4 mt-4" @click="update">submit</v-btn>
                </form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        props: ["userData"],
        name: "permissionDivisionModal",
        watch: {

        },
        data() {
            return {
                updated: false,
                permissionData: [],
                updatedData: [],
                permission: {},
                province: {},
                division: {},
                branch: {},
                formData: {
                    userID: "",
                    isActive: false,
                    role: ""
                },
                servicesData:{
                    data:{},
                    message:"",
                    color:""
                }
            };
        },
        mounted() {
            this.formOption();
        },
        methods: {
            async formOption() {
                console.log();
                const response = await axios.get("User/GetDivisionUserAccess", {
                    params: {
                        UserID: this.userData.userID,
                        DivisionID: this.userData.divisionID
                    }
                });
                this.permission = response.data.list;
                this.serverData = response.data;
                response.data.list.forEach(element => {
                    this.permissionData[element.menuName + "_R"] = element.readAccess;
                    this.permissionData[element.menuName + "_W"] = element.readWriteAccess;
                    this.permissionData[element.menuName + "_E"] = element.exportAccess;
                    this.permissionData[element.menuName + "_P"] = element.printAccess;
                });
                console.log(this.permissionData);
            },
            update() {
                let data = this.serverData;
                axios
                    .post("User/UpdateDivisionUserAccess", data)
                    .then(response => {
                            this.servicesData.data=response.data
                            this.servicesData.message=response.data.success?"Roles Updated Successfully":response.data.errors[0]
                            this.servicesData.color=response.data.success?"success":'error'
                        if (response.status === 200) {
                            this.dialogueClose()
                        }
                    })
                    .catch(e => {
                        this.servicesData.data=e.data
                        this.servicesData.message="Error Updating Roles, Please Contact Admin"
                        this.servicesData.color="error"
                        this.dialogueClose()

                    });
            },
            serverDataUpdate(index, access, e) {
                console.log(index, access, e);
                this.serverData.list.forEach((data, i) => {
                    if (i === index) {
                        if (access === "readAccess") {
                            data.readAccess = e;
                        }
                        if (access === "readWriteAccess") {
                            data.readWriteAccess = e;
                        }
                        if (access === "exportAccess") {
                            data.exportAccess = e;
                        }
                        if (access === "printAccess") {
                            data.printAccess = e;
                        }
                    }
                });
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
        }
    };
</script>

<style lang="scss" scoped>
    .hr-divider {
        width: 100%;
        max-width: 100%;
        border-width: 1px;
    }
</style>