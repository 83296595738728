<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
        <v-toolbar-title>
            {{$t('edit_user_password')}}
        </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
          <form>

              <!--<v-text-field
      v-model="editedUser.password"
      :error-messages="passwordErrors"
      label="Password"
      required
      @input="$v.editedUser.password.$touch()"
      @blur="$v.editedUser.password.$touch()"
      :append-icon="show10 ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required, rules.min]"
      type="show10 ? 'text' : 'password'"
      name="input-10-1"
      hint="At least 8 characters"
      counter
      @click:append="show10 = !show10"
    ></v-text-field>-->
<!-- 
              <v-text-field v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Enter your new Password"
                            hint="At least 8 characters"
                            counter
                            @click:append="show1 = !show1"></v-text-field> -->


                            <v-text-field
                                                v-model="editedUser.password"
                                                :label="$t('password')"
                                                required outlined dense
                                                type="password"
                                                :error-messages="passwordErrors"
                                                @input="$v.editedUser.password.$touch()"
                                                @blur="$v.editedUser.password.$touch()"
                                        ></v-text-field>

              <!-- <v-text-field v-model="editedUser.password" label="Password"></v-text-field>
    <v-text-field v-model="editedUser.confirmPassword" label="Confirm Pasword"></v-text-field>-->
          </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "resetPasswordForm",
  props: ["userInfoDialog", "success"],
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.editedUser.password.$dirty) return errors;
      !this.$v.editedUser.password.required &&
        errors.push("Password is required.");
      return errors;
    },
  },
 //watch: {
  //  "editedUser.provinceID": {
  //    handler: async function(val) {
  //      if (val) {
  //        this.division = [];
  //        this.branch = [];
  //        let url = "Division/DDLDivisionList";
  //        const divisionBranch = await axios.get(url, {
  //          params: { ProvinceID: val }
  //        });
  //        if (divisionBranch.data[0]) {
  //          this.division = divisionBranch.data;
  //        } else {
  //          this.editedUser.divisionID = 0;
  //          this.editedUser.branchID = 0;
  //        }

  //        if (this.watchProvince) {
  //          this.editedUser.divisionID = val;
  //        }
  //        this.watchProvince = true;
  //      }
  //    },
  //    deep: true
  //  },
  //  "editedUser.divisionID": {
  //    handler: async function(val) {
  //      if (val) {
  //        this.branch = [];
  //        const branch = await axios.get("Branch/DDLBranchList", {
  //          params: { DivisionID: val }
  //        });
  //        if (branch.data[0]) {
  //          this.branch = branch.data;
  //        } else {
  //          this.editedUser.branchID = 0;
  //        }

  //        if (this.watchDivision) {
  //          this.editedUser.branchID = val;
  //          this.watchSector = true;
  //        }
  //      }
  //    },
  //    deep: true
  //  }
  //},
  data() {
      return {
          watchDivision: false,
          watchProvince: false,
          updated: false,
          show2: false,
          provShow: false,
          divShow: false,
          province: [],
          division: [],
          branch: [],
          editedUser: {},
          servicesData:{
            data:{},
            message:"",
            color:""
          }
      };
  },
  validations: {
    editedUser: {
      password: {required }
    }
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData)
      this.$emit("formResponse", this.servicesData);
    },
    submit() {
      console.log("test")
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var formData = new FormData();
        formData.append(
          "UserDetails.Password",
          this.editedUser.password
        );
        
          formData.append("isAnonymous", "false");
          axios.post("User/ChangePasswordAdminAsync", { params: { UserId: this.userInfoDialog, NewPassword: this.editedUser.password } }).then(response => {
          this.editedData = {};
          this.servicesData.data=response.data
          this.servicesData.message="User Password Updated Succcessfully"
          this.servicesData.color="success"
          this.dialogueClose()
        }).catch(response=>{
          this.servicesData.data=response.data
          this.servicesData.message="Error Adding Service, Please Contact Admin"
          this.servicesData.color="error"
          this.dialogueClose()

        });
      }
    },
    async formData() {
      let permissionData = JSON.parse(localStorage.getItem("tokenData"));
      if (permissionData.role == "Ministry") {
        this.provShow = true;
        this.divShow = true;
        const provinceRequest = await axios.post("Province/ProvinceList", {});
        this.province = provinceRequest.data.data;
      } else if (permissionData.role == "Province") {
        this.divShow = true;
      }
      const provinceRequest = await axios.get("Province/DDLProvinceList");
      this.province = provinceRequest.data;
        axios
            .get("User/GetUserByID", { params: { UserID: this.userInfoDialog }})
        .then(response => {
          this.editedUser= response.data
        });
    }
  }
};
</script>

<style scoped>
</style>