<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Assign Permission
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>  
                <form>
                    <v-expansion-panels multiple>
                        <v-row>
                            <v-col cols="12" md="4" v-for="(formData,index) in permission" :key="formData.id">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>{{formData.menuName}}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_R']"
                                                @change="serverDataUpdate(index,'readAccess',$event)"
                                                label="Read"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_W']"
                                                @change="serverDataUpdate(index,'readWriteAccess',$event)"
                                                label="Read Write"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_E']"
                                                @change="serverDataUpdate(index,'exportAccess',$event)"
                                                label="Export"
                                        ></v-checkbox>
                                        <v-checkbox
                                                v-model="permissionData[formData.menuName+'_P']"
                                                @change="serverDataUpdate(index,'printAccess',$event)"
                                                label="Print"
                                        ></v-checkbox>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-col>
                        </v-row>
                    </v-expansion-panels>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        props: ["userInfoDialog"],
        name: "permissionModal",
        watch: {
            updated: {
                handler: function (val) {
                    this.$emit("formResponse", val);
                },
                deep: true
            }
        },
        data() {
            return {
                parentResponseData: {},
                updated: false,
                permissionData: [],
                updatedData: [],
                permission: {},
                province: {},
                division: {},
                branch: {},
                formData: {
                    userID: "",
                    isActive: false,
                    role: ""
                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        mounted() {
            this.formOption();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formOption() {
                let response = {}
                console.log(this.tokenData)
                if (this.tokenData.role == "Branch") {
                    response = await axios.get("Branch/GetBranchUserAccess", {
                        params: {
                            UserID: this.userInfoDialog,
                            Type: "Hotel"
                        }
                    });
                } else {
                    response = await axios.get("User/GetUserAccess", {
                        params: {UserID: this.userInfoDialog}
                    });

                }


                this.permission = response.data.list;
                this.serverData = response.data;
                response.data.list.forEach(element => {
                    this.permissionData[element.menuName + "_R"] = element.readAccess;
                    this.permissionData[element.menuName + "_W"] = element.readWriteAccess;
                    this.permissionData[element.menuName + "_E"] = element.exportAccess;
                    this.permissionData[element.menuName + "_P"] = element.printAccess;
                });
                console.log(this.permissionData);
            },
            submit() {
                let data = this.serverData;
                if (this.tokenData.role == "Branch") {
                    axios
                        .post("Branch/UpdateBranchUserAccess", data)
                        .then(response => {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Permission Updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        })
                        .catch(response => {
                            this.servicesData.data = response.data
                            this.servicesData.message = "Error Adding Service, Please Contact Admin"
                            this.servicesData.color = "error"
                            this.dialogueClose()
                        });
                } else {
                    axios
                        .post("User/UpdateUserAccess", data)
                        .then(response => {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Permission Updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()

                        })
                        .catch(response => {
                            this.servicesData.data = response.data
                            this.servicesData.message = "Error Adding Service, Please Contact Admin"
                            this.servicesData.color = "error"
                            this.dialogueClose()
                        });
                }
            },
            serverDataUpdate(index, access, e) {
                console.log(index, access, e);
                this.serverData.list.forEach((data, i) => {
                    if (i == index) {
                        if (access == "readAccess") {
                            data.readAccess = e;
                        }
                        if (access == "readWriteAccess") {
                            data.readWriteAccess = e;
                        }
                        if (access == "exportAccess") {
                            data.exportAccess = e;
                        }
                        if (access == "printAccess") {
                            data.printAccess = e;
                        }
                    }
                });

                // var update = this.serverData;
                // update.data.forEach(element => {
                //   if (element.menuName == name) {
                //     this.updatedData[nam
                //   }
                // });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .hr-divider {
        width: 100%;
        max-width: 100%;
        border-width: 1px;
    }
</style>