<template>
  <v-content>
    <v-breadcrumbs :items="items" large></v-breadcrumbs>

    <v-snackbar
      v-model="snackbar"
      :color="callbackResponse.color"
      :timeout="callbackResponse.timeout"
      top
    >
      {{ callbackResponse.message }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogCreate" max-width="500px" scrollable>
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2 float-right" v-on="on">{{
          $t("navBarLogin.register_user")
        }}</v-btn>
      </template>
      <createAdminForm :success="success" @formResponse="onResponse" />
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="500px">
      <editAdminForm
        v-if="dialogEdit"
        :userInfoDialog="editedUser.userID"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>

    <v-dialog v-model="dialogResetPassword" max-width="500px">
      <resetPasswordForm
        v-if="dialogResetPassword"
        :userInfoDialog="editedUser.userID"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>

    <v-dialog v-model="dialogRole" max-width="500px">
      <editRoleForm
        v-if="dialogRole"
        :userInfoDialog="editedUser.userID"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>
    <v-dialog v-model="dialogPermission" max-width="900px">
      <editPermissionForm
        v-if="dialogPermission"
        :userInfoDialog="editedUser.userID"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>
    <v-dialog v-model="dialogDivisionPermission" max-width="900px">
      <editDivisionPermission
        v-if="dialogDivisionPermission"
        :userInfoDialog="editedUser"
        :success="success"
        @formResponse="onResponse"
      />
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponse"
      ></deleteListData>
    </v-dialog>
    <v-expansion-panels hover>
      <v-expansion-panel class="fix-for-nepali-label">
        <v-expansion-panel-header>{{
          $t("table.filter")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row align="center" justify="end">
              <v-col class="d-flex" cols="12" sm="4">
                <v-autocomplete
                  v-model="serverParams.ProvinceID"
                  :items="ProvinceList"
                  :label="$t('form.select_province')"
                  clearable  outlined dense
                  @change="getDivisionList"
                  item-text="value"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-if="isSuperAdmin || isMinistry || isAssociation">
                <v-autocomplete
                  v-model="serverParams.DivisionID"
                  :items="DivisionList"
                  :label="$t('form.sector_name')"
                  clearable  outlined dense
                  @change="getBranchList"
                  item-text="value"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-if="isSuperAdmin || isMinistry || isAssociation">
                <v-autocomplete
                  v-model="serverParams.BranchID"
                  :items="BranchList"
                  :label="$t('form.branch_name')"
                  clearable  outlined dense
                  item-text="value"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-text-field
                  clearable  outlined dense
                  v-model="serverParams.Email"
                  :label="$t('email')"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-text-field
                  clearable  outlined dense
                  v-model="serverParams.UserName"
                  :label="$t('user_name')"
                ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                <v-text-field
                  clearable  outlined dense
                  v-model="serverParams.FirstName"
                  :label="$t('table.first_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  clearable  outlined dense
                  v-model="serverParams.LastName"
                  :label="$t('table.last_name')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                <i class="material-icons">search</i>{{ $t("search") }}
              </v-btn>
              <v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                <i class="material-icons">clear</i>{{ $t("clear") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{ enabled: true }"
      :rows="rows"
      :columns="columns"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'activeData'">
          <v-checkbox
            v-model="props.row.isActive"
            @change="changeStatus(props.row)"
          ></v-checkbox>
        </span>
        <span v-if="props.column.field === 'actions'">
          <v-icon
            small
            class="mr-2"
            @click="editItem(props.row)"
            v-if="props.row.show"
            >edit</v-icon
          >
          <v-icon small class="disable mr-2" @click="doNothing" v-else disabled
            >edit</v-icon
          >
          <v-icon small @click="deleteItem(props.row)">delete</v-icon>
          <v-icon small @click="resetPassword(props.row)" class="ml-3"
            >restore</v-icon
          >
        </span>
        <span v-else>{{ props.formattedRow[props.column.field] }}</span>

        <span v-if="props.column.field === 'roles'">
          <v-btn @click="dialogRoleModal(props.row)">{{
            $t("table.assign_roles")
          }}</v-btn>
        </span>

        <span v-if="props.column.field === 'permission'">
          <span v-if="props.row.role === 'User'">Not Available</span>
          <span v-else>
            <v-btn @click="dialogPermissionModal(props.row)"
              >Assign Permission</v-btn
            >
          </span>
        </span>

        <span v-if="props.column.field === 'branchPermision'">
          <span>
            <v-btn @click="dialogPermissionModal(props.row)"
              >Assign Permission</v-btn
            >
          </span>
        </span>

        <span v-if="props.column.field === 'divisionPermision'">
          <span
            v-if="
              props.row.role === 'Ministry' || props.row.role === 'Province'
            "
            >Not Available</span
          >
          <span v-else>
            <v-btn @click="dialogDivisionPermissionModal(props.row)"
              >Assign Permission</v-btn
            >
          </span>
        </span>
      </template>
    </vue-good-table>
  </v-content>
</template>

<script>
import axios from "axios";
import createAdminForm from "./create";
import editAdminForm from "./edit";
import resetPasswordForm from "./resetPassword.vue";
import editRoleForm from "./roleForm";
import editPermissionForm from "./permissisonForm";
import editDivisionPermission from "./permissisonDivisionForm";
    import deleteListData from "../../components/deleteModal";
    import { mapGetters } from "vuex";

export default {
  name: "listUserAdminForm",
  watch: {
    dialog(val) {
      val || this.close();
    },
    success: {
      handler: function(val, oldVal) {
        console.log(val, oldVal);
        this.loadItems();
      },
      deep: true,
    },
  },
  components: {
    createAdminForm,
    editAdminForm,
    resetPasswordForm,
    editRoleForm,
    editPermissionForm,
    editDivisionPermission,
    deleteListData,
  },
  computed: {
    permissionData() {
      return JSON.parse(localStorage.getItem("tokenData"));
      },

      ...mapGetters(['getSystemUserData']),
      isMinistry() {
          return this.getSystemUserData.role === 'Ministry'
      },
      isSuperAdmin() {
          return this.getSystemUserData.role === 'SuperAdmin'
      },
      isAssociation() {
          return this.getSystemUserData.role === 'Association'
      },

    columns() {
      let returns = [
        {
          label: this.$t("table.sn"),
          field: "countIndex",
        },
        {
          label: this.$t("table.first_name"),
          field: "firstName",
        },
        {
          label: this.$t("table.last_name"),
          field: "lastName",
        },
        {
          label: this.$t("email"),
          field: "email",
        },
        {
          label: this.$t("table.role"),
          field: "role",
          sortable: false,
        },
        {
          label: this.$t("table.active"),
          field: "activeData",
          sortable: false,
        },
      ];
      if (
        !(this.permissionData.role === "Division") &&
        !(this.permissionData.role === "Branch")
      ) {
        returns.push({
          label: this.$t("table.roles"),
          field: "roles",
          sortable: false,
        });
      }
      returns.push({
        label: this.$t("table.actions"),
        field: "actions",
        sortable: false,
      });
      return returns;
    },
  },
  destroyed() {},
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      BranchList: [],
      DivisionList: [],
      ProvinceList: [],
      snackbar: false,
      success: false,
      dialogCreate: false,
      dialogEdit: false,
      dialogResetPassword: false,
      dialogDelete: false,
      dialogRole: false,
      dialogPermission: false,
      dialogDivisionPermission: false,
      options: ["Foo", "Bar", "Fizz", "Buzz"],
      items: [],
      popout: true,
      isLoading: false,
      deleteFormData: {},
      rows: [],
      totalRecords: 0,
      serverParams: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "userID",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },
      user: [],
      editedIndex: -1,
      editedUser: {
        userID: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    doNothing() {
      event.target.disabled = true;
    },
    changeStatus(val) {
      axios.post("UserAccessRoles/UpdateUserStatus", val).then((response) => {
        if (!response.data.success) {
          this.snackbar = true;
          this.callbackResponse.color = "error";
          this.callbackResponse.message = response.data.errors[0];
        } else {
          this.snackbar = true;
          this.callbackResponse.color = "success";
          this.callbackResponse.message = "Status Updated Successfully";
        }
      });
    },
    onResponse(data) {
      console.log(data);
      if (data.message) {
        this.dialogCreate = false;
        this.dialogEdit = false;
        this.dialogResetPassword = false;
        this.dialogDelete = false;
        this.dialogRole = false;
        this.dialogPermission = false;
        this.dialogDivisionPermission = false;
        this.callbackResponse = data;
        this.snackbar = true;
        this.loadItems();
      } else {
        this.dialogCreate = false;
        this.dialogEdit = false;
        this.dialogResetPassword = false;
        this.dialogDelete = false;
        this.dialogRole = false;
        this.dialogPermission = false;
        this.dialogDivisionPermission = false;
      }
    },
    dialogRoleModal(response) {
      this.editedUser.userID = response.userID;
      this.dialogRole = true;
    },
    dialogPermissionModal(response) {
      this.editedUser.userID = response.userID;
      this.dialogPermission = true;
    },
    dialogDivisionPermissionModal(response) {
      console.log(response);
      this.editedUser.userID = response.userID;
      this.editedUser.divisionID = response.divisionID;
      this.dialogDivisionPermission = true;
    },
    //called form child components
    onUpdated(data) {
      if (data === true) {
        this.dialogEdit = false;
        this.dialogResetPassword = false;
        this.loadItems();
      }
    },
    editItem: function(response) {
      console.log(response);
      this.editedUser.userID = response.userID;
      this.dialogEdit = true;
      console.log("test");
    },
    resetPassword: function(response) {
      console.log(response);
      this.editedUser.userID = response.userID;
      this.dialogResetPassword = true;
      console.log("test");
    },
    deleteItem(props) {
      this.dialogDelete = true;
      this.deleteFormData.url = "User/DeleteUser/" + props.userID;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedUser = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    clearInput() {
      this.serverParams.ProvinceID = null;
      this.serverParams.DivisionID = null;
      this.serverParams.BranchID = null;
      this.serverParams.UserName = null;
      this.serverParams.Email = null;
      this.serverParams.FirstName = null;
      this.serverParams.LastName = null;
      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      this.loadAllItems();
      this.getProvinceList();
    },
    // load items is what brings back the rows from server
    async loadAllItems() {
      // eslint-disable-next-line no-console
      //if (this.totalRecords < this.serverParams.perPage) {
      //  this.serverParams.perPage = 10;
      //  this.serverParams.page = 1;
      //}
      let param = {
        PageSize: this.serverParams.perPage == -1?this.totalRecords:this.serverParams.perPage,
        PageNo: this.serverParams.page,
        OrderType: this.serverParams.sort[0].type,
        OrderBy: this.serverParams.sort[0].field,
        ProvinceID: this.serverParams.ProvinceID,
        DivisionID: this.serverParams.DivisionID,
        BranchID: this.serverParams.BranchID,
        UserName: this.serverParams.UserName,
        Email: this.serverParams.Email,
        FirstName: this.serverParams.FirstName,
        LastName: this.serverParams.LastName,
      };
      this.isLoading = true;
      try {
        let list = await axios.post("User/UserList", param);
        this.rows = list.data.data;
          this.totalRecords = list.data.totalCount;
          console.log('data', list);
        this.isLoading = false;
      } catch (e) {
        this.loadItems();
      }
    },

    async getProvinceList() {
      const ProvinceList = await axios.get("Province/DDLProvinceList");
      this.ProvinceList = ProvinceList.data;
      console.log("province list: ", this.ProvinceList);
    },
    async getDivisionList(id) {
      const DivisionList = await axios.get(
        "Division/DDLDivisionList?ProvinceID=" + id
      );
      this.DivisionList = DivisionList.data;
      console.log("DivisionList", DivisionList);
    },
    async getBranchList(id) {
      const BranchList = await axios.get(
        "Branch/DDLBranchList?DivisionID=" + id
      );
      this.BranchList = BranchList.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  background: #d2e3ff73;
}

.v-expansion-panel-header {
  background: #d2e3ff73;
}
.disable {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
